*{
--orange: '#fed693'
}
.heroneon {
    position: relative;
    display: inline-block;
    padding: 20px 25px;
    margin: 30px 0;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 1px;
    overflow: hidden;
    font-size: 1.2rem;
    font-weight: 700;
  }

  @media screen and (max-width: 768px) {
    .heroneon {
      font-size: 1rem;
    }
  }
  .heroneon:hover {
    background: linear-gradient(-90deg, #1b5300, #bb051f);
    color: #fff;
    box-shadow: 0 0 5px #bb051f, 0 0 25px #bb051f, 0 0 50px #bb051f,
      0 0 200px #bb051f;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #000);
  }
  .heroneon:nth-child(1) {
    filter: hue-rotate(0deg);
  }
  .heroneon:nth-child(2) {
    filter: hue-rotate(110deg);
  }
  .heroneon span {
    position: absolute;
    display: block;
  }
  .heroneon span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, 	#3f8f29);
    animation: animate1 1s linear infinite;
  }
  @keyframes animate1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  .heroneon span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #3f8f29);
    animation: animate2 1s linear infinite;
    animation-delay: 0.25s;
  }
  @keyframes animate2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  .heroneon span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, 	#3f8f29);
    animation: animate3 1s linear infinite;
    animation-delay: 0.5s;
  }
  @keyframes animate3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  .heroneon span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #3f8f29);
    animation: animate4 1s linear infinite;
    animation-delay: 0.75s;
  }
  @keyframes animate4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }

  .text_title{
    text-transform: uppercase; 
    background-image: linear-gradient(
      225deg,
      #c25f5f 0%,
      #bb051f 29%,
      #3f8f29 45%,
      #056517 67%,
      #1b5300 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
  }

  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }