.banner {
  z-index: 100;
  position: relative;
  .banner-row {
    overflow: hidden;
    display: flex;
    align-items: center;
    @media (max-width: $layout-breakpoint-medium) {
      &:first-child {
        margin-top: 48px;
      }
    }
    &.center {
      justify-content: center;
      text-align: center;
      .row-letter {
        left: 0;
      }
    }
    &.right {
      justify-content: flex-end;
    }
    .row-title,
    .row-letter {
      font-size: 7rem;
      font-weight: 00;
      position: relative;
      letter-spacing: -0.3rem;
      text-transform: uppercase;
      display: inline-block;
      white-space: nowrap;
      color: var(--third-color);
      @media (max-width: $layout-breakpoint-xsmall) {
        font-size: 3rem;
        letter-spacing: -0.2rem;
      }
    }
    .row-col {
      width: 50%;
      display: flex;
      align-content: center;
      justify-content: center;
      @media (max-width: $layout-breakpoint-medium) {
        width: 70%;
      }
      @media (max-width: 1240px) {
        &:last-child {
          display: none;
        }
      }
      @media (max-width: $layout-breakpoint-xsmall) {
        width: 100%;
        justify-content: flex-start;
      }
      .row-message {
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.6rem;
        width: 320px;
      }
    }
    .scroll {
      height: 160px;
      width: 160px;
      border-radius: 100%;
      background: $white;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      left: 160px;
      @media (max-width: $layout-breakpoint-medium) {
        left: 60px;
      }
      @media (max-width: 1240px) {
        display: none;
      }
      span {
        font-size: 18px;
        font-weight: 600;
        margin: 4px 0;
        @media (max-width: $layout-breakpoint-xsmall) {
          font-size: 10px;
          margin: 2px 0;
        }
      }
    }
  }
}

.transition-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 800px;
    display: flex;
  }
  &.final {
    display: block;
    top: -128px;
    position: relative;
    width: 90%;
    margin: 0 auto;
    z-index: -100;
    @media (max-width: $layout-breakpoint-xsmall) {
      top: 56px;
      max-height: 50vh;
    }
    img {
      width: 100%;
      max-width: 100%;
    }
  }
}
